import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import icons from react-icons
import './Styles/PaymentDetailModal.css'; // Add your custom styles here
const PaymentDetailModal = ({ isOpen, onClose, paymentRecord }) => {
    if (!isOpen || !paymentRecord) return null;

    console.log(paymentRecord); // Check the payment record structure

    const modalClass = `ball-content ${paymentRecord.status === 'Success' ? 'success' : 'error'}`;

    return (
        <div className="hat-overlay">
            <div className={modalClass}>
                <h2>
                    {paymentRecord.status === 'Success' ? (
                        <FaCheckCircle className="status-icon success-icon" />
                    ) : (
                        <FaTimesCircle className="status-icon error-icon" />
                    )}
                    Payment Details
                </h2>
                <p><strong>Amount Paid:</strong> ₹{paymentRecord.amount}</p>
                <p><strong>Payment Date:</strong> {paymentRecord.paymentDate}</p>
                <p><strong>Expiry Date:</strong> {paymentRecord.expiryDate}</p> {/* This should display the expiry date */}
                <p><strong>Transaction ID:</strong> {paymentRecord.transactionId}</p>
               
                <button className="ball-close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};


// Type checking for props
PaymentDetailModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    paymentRecord: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired,
        transactionId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        expiryDate: PropTypes.string.isRequired, // Add expiryDate to prop types
    }).isRequired,
};

export default PaymentDetailModal;
