import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../firebase';
import './Styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCreditCard, faEye ,faHeart, faHeadset, faLock, faTrash, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  const moreMenuRef = useRef(null);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
      toast.success('Logged out successfully.');
    } catch (error) {
      toast.error('Failed to log out.');
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleMoreMenu = () => {
    setMoreOpen(!moreOpen);
  };

  // Close the menu if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
        setMoreOpen(false);
      }
      if (!document.querySelector('.navbar').contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-brand" onClick={() => navigate('/home')}>
        Karanki Matrimony
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
      <button onClick={() => navigate('/home')}>
        <FontAwesomeIcon icon={faHome} /> Home
      </button>
      <button onClick={() => navigate('/profile')}>
        <FontAwesomeIcon icon={faUser} /> My Profile
      </button>
      <button onClick={() => navigate('/payment')}>
        <FontAwesomeIcon icon={faCreditCard} /> Subscription
      </button>
      <button onClick={() => navigate('/recently-viewed')}>
        <FontAwesomeIcon icon={faEye} /> Recently Viewed
      </button>
        
        <button onClick={toggleMoreMenu} className="more-button">
          More
        </button>
        {moreOpen && (
          <div className="more-menu" ref={moreMenuRef}>
             <button onClick={() => navigate('/matching-preference')}>
        <FontAwesomeIcon icon={faHeart} /> Matching Preference
      </button>
      <button onClick={() => navigate('/contact')}>
        <FontAwesomeIcon icon={faHeadset} /> Customer Support
      </button>
      <button onClick={() => navigate('/settings')}>
        <FontAwesomeIcon icon={faLock} /> Update Password
      </button>
      <button onClick={() => navigate('/delete-account')}>
        <FontAwesomeIcon icon={faTrash} /> Delete Account
      </button>
      <button onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </button>
            
          </div>
        )}
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
