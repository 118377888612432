// src/Components/SubscriptionPage.js

import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase'; // Ensure 'auth' is exported from your firebase config
import { doc, getDoc } from 'firebase/firestore';
import Navbar from './Navbar'; // Import your Navbar component
import './Styles/Subcription.css'; // Import your CSS styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelUpAlt,faPhone, faCommentDots } from '@fortawesome/free-solid-svg-icons'; 
import { Link } from 'react-router-dom';
const Subcription = () => {
  const [loading, setLoading] = useState(true);
  const [isPaidMember, setIsPaidMember] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);

        try {
          const currentUserDocRef = doc(db, 'users', user.uid);
          const currentUserDoc = await getDoc(currentUserDocRef);
          if (currentUserDoc.exists()) {
            const currentUserData = currentUserDoc.data();
            setIsPaidMember(currentUserData.isPaidMember);
          } else {
            console.error('Current user data not found in Firestore.');
          }
        } catch (error) {
          console.error('Error fetching current user details:', error);
        }
      } else {
        console.error('No user is currently logged in.');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleUpgradeClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      closeModal();
    }
  };

  const handleUpgradeContact = (contactMethod) => {
    if (contactMethod === 'call') {
      window.location.href = 'tel:+919912349925'; // Initiate a phone call
    } else if (contactMethod === 'chat') {
        const message = encodeURIComponent("Hello, I'm interested in upgrading my plan. Could you please provide information on the process for upgrading? Thank you!");


      window.open(`https://wa.me/+919912349925?text=${message}`, '_blank'); // Open WhatsApp chat
    }
    closeModal(); // Close the modal after attempting to contact support
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p className="loading-text">Loading...</p>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="subscription-page">
        <div className="subscription-content">
          <p className="login-message">Please log in to view your subscription details.<Link to="/login">Click here to login</Link></p>
         
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="subscription-page">
      <div className="subscription-content">
  <h1 className="subscription-title">Your Subscription Status</h1>
  {isPaidMember ? (
    <div className="membership-status paid">
      <h2 className="status-title">🎉 Congratulations!</h2>
      <p className="status-message">Thank you for being a valued member of Karanki Matrimony!</p>
      <p className="active-plan">Active Plan: <strong>Premium Membership</strong></p>
      <ul className="benefits-list">
        <li>✅ Unlimited Profiles</li>
        <li>✅ Contact Information</li>
        <li>✅ Priority Support</li>
        <li>✅ Exclusive Matches</li>
        
      </ul>
    </div>
  ) : (
    <div className="membership-status free">
      <h2 className="status-title">🚀 Upgrade Your Membership</h2>
      <p className="status-message">You are currently enjoying our Free Plan.</p>
      <p className="active-plan">Active Plan: <strong>Free Membership</strong></p>
      <ul className="benefits-list">
        <li>✅ Unlimited Profiles</li>
        <li>❌ Contact Information</li>
        <li>❌ Priority Support</li>
        <li>❌ Exclusive Matches</li>
      </ul>
      <button onClick={handleUpgradeClick} className="upgrade-button" title="Upgrade to Premium for more benefits!">
        <FontAwesomeIcon icon={faLevelUpAlt} /> Upgrade Now
      </button>
    </div>
  )}
</div>



        {modalOpen && (
          <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <h2 className="modal-title">Upgrade Your Plan</h2>
              <p className="modal-message">To upgrade your plan, please choose a contact method:</p>
              <div className="button-container"> {/* New container for buttons */}
              <button onClick={() => handleUpgradeContact('call')} className="upgrade-button">
        <FontAwesomeIcon icon={faPhone} /> Call Support
      </button>
      <button onClick={() => handleUpgradeContact('chat')} className="upgrade-button">
        <FontAwesomeIcon icon={faCommentDots} /> Chat on WhatsApp
      </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Subcription;
