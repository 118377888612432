import React from 'react';
import '../Styles/Register.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

const districtsAndhraPradesh = [
  "Anakapalli",
  "Ananthapuramu",
  "Annamayya",
  "Bapatla",
  "Chittoor",
  "Dr. B.R. Ambedkar Konaseema",
  "East Godavari",
  "Eluru",
  "Guntur",
  "Kakinada",
  "Krishna",
  "Kurnool",
  "Nandyal",
  "Ntr",
  "Palnadu",
  "Parvathipuram Manyam",
  "Prakasam",
  "Sri Potti Sriramulu Nellore",
  "Sri Sathya Sai",
  "Srikakulam",
  "Tirupati",
  "Visakhapatnam",
  "Vizianagaram",
  "West Godavari",
  "Y.S.R."
];

const districtsTelangana = ["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar Bhupalpally", "Jogulamba Gadwal", "Kamareddy", "Karimnagar", "Khammam", "Komaram Bheem", "Mahabubabad", "Mahabubnagar", "Mancherial", "Medak", "Medchal–Malkajgiri", "Mulugu", "Nagarkurnool", "Nalgonda", "Narayanpet", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Rangareddy", "Sangareddy", "Siddipet", "Suryapet", "Vikarabad", "Wanaparthy", "Warangal Rural", "Warangal Urban", "Yadadri Bhuvanagiri"];


const financialStatusOptions = [
  { value: "Middle Class", label: "Middle Class" },
  { value: "Upper Class", label: "Upper Class" },
  { value: "Lower Class", label: "Lower Class" },
  { value: "High Class", label: "High Class" }
];

const Step2 = ({ formData, setFormData }) => {
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    return (
      <div className="step-container">
       <h2 className="step-title">
  <FontAwesomeIcon icon={faUsers} style={{ marginRight: '8px' }} />
  Step 2: Family Information
</h2>
        
        <label className="form-label">Father's Name</label>
        <input
          type="text"
          name="fatherName"
          className="form-input"
          value={formData.fatherName || ''}
          onChange={handleInputChange}
          placeholder="Enter Father Name"
          required
        />
        
        <label className="form-label">Father's Profession</label>
        <input
          type="text"
          name="fatherProfession"
          className="form-input"
          value={formData.fatherProfession || ''}
          onChange={handleInputChange}
          required
        />
        
        <label className="form-label">Father's Designation</label>
        <input
          type="text"
          name="fatherDesignation"
          className="form-input"
          value={formData.fatherDesignation || ''}
          onChange={handleInputChange}
          required
        />
        
        <label className="form-label">Mother's Name</label>
        <input
          type="text"
          name="motherName"
          className="form-input"
          value={formData.motherName || ''}
          onChange={handleInputChange}
          placeholder="Enter Mother Name"
          required
        />
        
        <label className="form-label">Mother's Surname</label>
        <input
          type="text"
          name="motherSurname"
          className="form-input"
          value={formData.motherSurname || ''}
          onChange={handleInputChange}
          required
        />
        
        <label className="form-label">Mother's Profession</label>
        <input
          type="text"
          name="motherProfession"
          className="form-input"
          value={formData.motherProfession || ''}
          onChange={handleInputChange}
          required
        />

        
      {/* Alternative Mobile Number Input */}
      <label className="form-label">Alternative Mobile Number</label>
      <input
        type="tel"
        name="altMobileNumber"
        className="form-input"
        value={formData.altMobileNumber || ''}
        onChange={handleInputChange}
        placeholder="Enter Alternative Mobile Number"
      />

<label className="form-label">Number of Brothers</label>
<input
  type="text"
  name="numberOfBrothers"
  className="form-input"
  value={formData.numberOfBrothers || ''}
  onChange={handleInputChange}
  
  required
/>
<label className="form-label">Number of Sisters</label>
<input
  type="text"
  name="numberOfSisters"
  className="form-input"
  value={formData.numberOfSisters || ''}
  onChange={handleInputChange}
  required
/>

        <label className="form-label">Native State</label>
        <select
          name="state"
          className="form-select"
          value={formData.state || ''}
          onChange={handleInputChange}
          required
        >
          <option value="">Select State</option>
          <option value="Andhra Pradesh">Andhra Pradesh</option>
          <option value="Telangana">Telangana</option>
          <option value="Other">Other</option>
        </select>

        {formData.state === 'Andhra Pradesh' && (
          <div className="form-group">
            <label className="form-label">District</label>
            <select
              name="nativePlaceDistrict"
              className="form-select"
              value={formData.nativePlaceDistrict || ''}
              onChange={handleInputChange}
              required
            >
              <option value="">Select District</option>
              {districtsAndhraPradesh.map(district => (
                <option key={district} value={district}>{district}</option>
              ))}
            </select>
          </div>
        )}

        {formData.state === 'Telangana' && (
          <div className="form-group">
            <label className="form-label">District</label>
            <select
              name="nativePlaceDistrict"
              className="form-select"
              value={formData.nativePlaceDistrict || ''}
              onChange={handleInputChange}
              required
            >
              <option value="">Select District</option>
              {districtsTelangana.map(district => (
                <option key={district} value={district}>{district}</option>
              ))}
            </select>
          </div>
        )}

        {formData.state === 'Other' && (
          <div className="form-group">
            <label className="form-label">Native State</label>
            <input
              type="text"
              name="nativeStateOther"
              className="form-input"
              value={formData.nativeStateOther || ''}
              onChange={handleInputChange}
              placeholder="Enter Native State"
              required
            />
            
            <label className="form-label">District</label>
            <input
              type="text"
              name="nativePlaceDistrict"
              className="form-input"
              value={formData.nativePlaceDistrict || ''}
              onChange={handleInputChange}
              placeholder="Enter District"
              required
            />
          </div>
        )}

        <label className="form-label">Native Place</label>
        <input
          type="text"
          name="place"
          className="form-input"
          value={formData.place || ''}
          onChange={handleInputChange}
          placeholder="Enter Native Place" 
          required
        />
        
        <label className="form-label">Present Residence</label>
        <input
          type="text"
          name="presentResidence"
          className="form-input"
          value={formData.presentResidence || ''}
          onChange={handleInputChange}
          placeholder="Enter Present Residence" 
          required
        />
        <label className="form-label">Financial Status</label>
<select name="financialStatus" className="form-select" value={formData.financialStatus} onChange={handleInputChange}>
  <option value="">Select Financial Status</option>
  {financialStatusOptions.map((status) => (
    <option key={status.value} value={status.value}>
      {status.label}
    </option>
  ))}
</select>




      </div>
    );
};

export default Step2;
