import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Import Firebase authentication module
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify'; // Import toast functions
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast
import './Styles/SettingsPage.css';
import Navbar from './Navbar';

const SettingsPage = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) setEmail(user.email || '');
      setLoading(false); // Set loading to false after authentication check
    });
    return () => unsubscribe();
  }, []);

  const handleUpdatePassword = async () => {
    setLoading(true); // Set loading to true while updating the password
    if (newPassword === confirmPassword) {
      try {
        const credential = EmailAuthProvider.credential(email, password);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        toast.success('Password updated successfully.');
      } catch (error) {
        toast.error('Current password is incorrect.');
      }
    } else {
      toast.error('Passwords do not match.');
    }
    setLoading(false); // Set loading to false after the operation
  };

  

  return (
    <div>
      <Navbar />
      <div className="settings-page">
        <div className="settings-container">
          <div className="settings-form">
            <h2 className="update-password-heading">Update Password</h2>
            <div className="form-group">
              <label className="form-label">Email:</label>
              <input
                className="form-input"
                type="email"
                value={email}
                readOnly
              />
            </div>
            <div className="form-group">
              <label className="form-label">Current Password</label>
              <input
                className="form-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your current password"
              />
            </div>
            <div className="form-group">
              <label className="form-label">New Password</label>
              <input
                className="form-input"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter your new password"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Confirm New Password</label>
              <input
                className="form-input"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your new password"
              />
            </div>
            <button className="update-password-button" onClick={handleUpdatePassword} disabled={loading}>
              {loading ? 'Updating...' : 'Update Password'}
            </button>
          </div>
          <ToastContainer /> {/* Toast container to render toasts */}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
