import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBvcRl0Vll8MD24dBjfkxct2xK1RSy2HsU",
    authDomain: "matrimony-c6e96.firebaseapp.com",
    projectId: "matrimony-c6e96",
    storageBucket: "matrimony-c6e96.appspot.com",
    messagingSenderId: "442340372308",
    appId: "1:442340372308:web:6bca0edc3c1c8d798ce2c1",
    measurementId: "G-FBR95N7SKG"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
