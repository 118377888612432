import React, { useState, useEffect } from 'react';
import './Styles/Rateus.css';
import feedbackImage from '../Assets/feedback.jpg'; // Use more descriptive variable names

const RateUs = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setIsPopupVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsPopupVisible(false);
    window.location.href = '/'; // Redirect to home page
  };

  return (
    <div className="rateus-popup-container">
      {isLoading && <div className="loading-animation"></div>}
      {isPopupVisible && (
        <div className="rateus-popup">
          <span className="rateus-close-icon" onClick={handleClose}>&times;</span>
          <div className="rateus-popup-content">
            <img
              src={feedbackImage} // More descriptive variable name
              alt="Feedback"
              className="rateus-feedback-image"
            />
            <p>We value your feedback! Please take a moment to share your thoughts by leaving a review.</p>
            <button onClick={() => window.open('https://g.page/r/CR2ydwtqfuZjEB0/review', '_blank')} className="rateus-review-button">Write a Review</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RateUs;
