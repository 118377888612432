import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import './Styles/ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent!');
      setEmail(''); // Clear the input field after sending
    } catch (error) {
      toast.error('Error sending reset email. Please check your email.');
    }
  };

  return (
    <div className="forgot-password-container">
      <h2 className="forgot-password-heading">Forgot Password</h2>
      <p className="forgot-password-description">
        Enter your email address below and we'll send you instructions to reset your password.
      </p>
      <div className="forgot-password-form">
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          type="email"
          className="forgot-password-input"
        />
        <button onClick={handleForgotPassword} className="forgot-password-button">
          Reset Password
        </button>
      </div>
      <p className="forgot-password-footer">
        Remember your password? <a href="/login" className="forgot-password-link">Login</a>
      </p>
    </div>
  );
};

export default ForgotPassword;
