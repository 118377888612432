import React from 'react';
import './Styles/Logsucc.css';  // You can add custom CSS here
import { FaCheckCircle } from 'react-icons/fa';  // Importing the check-circle icon
const Logsucc = () => {
 
  
  return (
    <div className="logsucc-container">
      <div className="success-message">
      <FaCheckCircle className="success-icon" />  {/* Icon added here */}
      <h1>Registration Successful!</h1>
        <p>Congratulations! Your registration was completed successfully.</p>
        
      
      </div>
    </div>
  );
};

export default Logsucc;
