import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Ensure Firebase is configured
import { doc, deleteDoc, getDoc } from 'firebase/firestore'; // Import Modular SDK functions
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'; // Import reauthentication functions
import './Styles/DeleteAccount.css'; // CSS file for styling
import Navbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const DeleteAccount = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState(''); // State for full name
  const [password, setPassword] = useState(''); // State for password
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setFullName(userData.fullName || 'User'); // Display the user's full name
        }
      }
    };

    fetchUserData();
  }, []);

  const handleDeleteAccount = async () => {
    if (!password) {
      alert('Please enter your password to confirm deletion.'); // Alert if password is not entered
      return; // Exit the function early if password is not provided
    }

    try {
      setLoading(true);
      const user = auth.currentUser;

      if (user) {
        // Re-authenticate the user
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);

        // Delete user data from Firestore using Modular SDK
        const userDocRef = doc(db, 'users', user.uid);
        await deleteDoc(userDocRef);

        // Delete authentication account
        await user.delete();

        alert('Your account has been deleted successfully.');
        navigate('/');
      }
    } catch (error) {
      console.error('Error deleting account:', error); // Log the error
      alert('Error deleting account: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    setShowConfirmModal(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
    setPassword(''); // Clear the password state
  };

  return (
    <div>
      <Navbar />

      <div className="hero-delete-account-container">
        <h2>{`Goodbye, ${fullName}`}</h2>
        <p>If your marriage is settled, you can permanently delete your account.</p>
        <button className="hero-delete-button" onClick={handleConfirmDelete}>
      <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '8px' }} />
      Delete My Account
    </button>

        {showConfirmModal && (
          <div className="hero-modal-overlay">
            <div className="hero-modal">
              <h3>Confirm Account Deletion</h3>
              <p>
                Are you sure you want to permanently delete your account? This action cannot be undone.
              </p>
              <h4>Please Enter your password to delete account !</h4>
              <input
                type="password"
                placeholder="Enter your password to confirm"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update password state
                required
                className="password-input" // Add class for styling
              />
             <button
        className="hero-confirm-button"
        onClick={handleDeleteAccount}
        disabled={loading}
      >
        <FontAwesomeIcon icon={faTrashAlt} /> Yes, Delete My Account
      </button>
      <button
        className="hero-cancel-button"
        onClick={handleCancelDelete}
        disabled={loading}
      >
        <FontAwesomeIcon icon={faTimesCircle} /> No, Keep My Account
      </button>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default DeleteAccount;
