import React from "react";
import "./Styles/Main.css"; // Ensure your CSS file is correctly referenced
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus ,faSignInAlt} from "@fortawesome/free-solid-svg-icons"; //
import { faHeart,faUserFriends,faLock, faHeadset ,faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Icon representing services
import MainNav from '../Components/MainNav';
import matrimonyImage from "../Assets/Looi.jpg"; // Import your matrimony achievements image
import { FaStar ,FaTrophy} from 'react-icons/fa'; // Importing a star icon from react-icons
import Footer from "./Footer";
import { Link } from 'react-router-dom'; 
import service from "../Assets/services.svg"; 
import achievementIcon from "../Assets/achivment.svg"; // Import your SVG image
import googlePlayIcon from "../Assets/gplay.png";
import feedback from "../Assets/feedback.jpg";
import customer from "../Assets/customer.png";
import privacy from "../Assets/privacy.png";
import match from "../Assets/match.png";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Import WhatsApp icon

const Main = () => {
  const handleRateUs = () => {
    // Link to the rating page or feedback form
    window.location.href = '/rate-us'; // Change the URL as necessary
  };
  const handleWhatsApp = () => {
    const message = "Hello! I'm reaching out from the Karanki Matrimony website and looking for matches. Could you help me find a suitable partner?";

    const phoneNumber = "+919912349925"; // Replace with your WhatsApp number
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };
  return (
    <div><MainNav/>
    <div className="lion-container">
    
    

<div className="dd">
<div className="tiger-auth-buttons">
    
    <Link to="/login">
        <button className="elephant-login-button">
          <FontAwesomeIcon icon={faSignInAlt} /> Login
        </button>
      </Link>
      <Link to="/register">
        <button className="giraffe-register-button">
          <FontAwesomeIcon icon={faUserPlus} /> Register
        </button>
      </Link>
     
    
</div>

</div>
 {/* Call to Action Section */}
 
 <section className="swan-cta">
  
 <img
          src={matrimonyImage} // Use imported hero image
          alt="Find Your Life Partner"
          className="penguin-hero-image"
        />
        <h3>Ready to Find Your Match?</h3>
        
        <p>
          Join Karanki Matrimony today and embark on your journey towards love. Sign up now to get started!
        </p>
         
        <Link to="/register">
      <button className="dove-cta-button">
        <FontAwesomeIcon icon={faUserPlus} /> Register
      </button>
    </Link>
    
      </section>
      {/* Hero Section */}
      <section className="whale-hero-section">
      <h1>Best Matrimony for Gowda and Other Castes</h1>
        <h2>Find Your Life Partner</h2>
        
      
        <p className="koala-hero-description">
          Discover the best matches for you with Karanki Matrimony. Join us to start your journey towards a beautiful relationship.
        </p>
      </section>

     

      {/* Services Section */}
      <section className="giraffe-services">
      <h3>
        <FontAwesomeIcon icon={faHeart} /> Our Services
      </h3>
      <img 
              src={service} 
              alt="Service Icon" 
              className="service-icon" 
            />
        <div className="services-list">
          <div className="zebra-service-item">
          <h4>
        <FontAwesomeIcon icon={faUserFriends} /> Personalized Matchmaking
      </h4>
            <p>
              We offer personalized matchmaking services to help you find the right partner based on your preferences and values.
            </p>
            <img 
              src={match} 
              alt="match  Icon" 
              className="match-icon" 
            />
          </div>
          <div className="kangaroo-service-item">
          <h4>
        <FontAwesomeIcon icon={faLock} /> Secure Platform
      </h4>
     
            <p>
              Our platform ensures your data privacy and security while you search for your life partner.
            </p>
            <img 
              src={privacy} 
              alt="privacy  Icon" 
              className="privacy-icon" 
            />
          </div>
          <div className="rabbit-service-item">
          <h4>
        <FontAwesomeIcon icon={faHeadset} /> Customer Support
        
      </h4>
   
            <p>
              Our dedicated support team is here to assist you at every step of your journey to find love.
            </p>
            <img 
              src={customer} 
              alt="Customer support Icon" 
              className="customer-icon" 
            />
          </div>
        </div>
      </section>
       {/* Achievements Section */}
       <section className="achievement-section">
          <h3>
            <FaTrophy style={{ marginRight: '8px', color: '#e74c3c' }} /> {/* Trophy Icon */}
            Our Achievements
          </h3>
          <div className="achievement-content">
            <img 
              src={achievementIcon} 
              alt="Achievements Icon" 
              className="achievement-icon" 
            />
            <p>
              We are proud to have successfully settled over <strong>400+ matches</strong> through our dedicated and personalized services.
              Our commitment to helping individuals find their perfect partners has made us a trusted name in matrimony.
              Join our growing family and let us assist you in finding your ideal match!
            </p>
          </div>
        </section>

        <hr></hr>

      {/* Testimonials Section */}
      <section className="turtle-testimonials">
        <h3>What Our Users Say</h3>
        <div className="testimonials-list">
        <div className="horse-testimonial-item">
      <h4>Ravi & Anjali</h4>
      <p>
        "Karanki Matrimony made our wedding dreams come true! With a wide selection of profiles and excellent customer support, they are undoubtedly the best matrimony platform. Thank you, Karanki!"
      </p>
    </div>
    <div className="cat-testimonial-item">
      <h4>Sita & Ramesh</h4>
      <p>
        "We had a wonderful experience using Karanki Matrimony. Their user-friendly platform and supportive team truly set them apart as the best matrimony service. Highly recommend to anyone searching for love!"
      </p>
    </div>
          
        </div>
        <br></br>
        <a href="https://g.co/kgs/TfA6YsH" class="custom-link">
        <button className="custom-button">
      View More <FontAwesomeIcon icon={faArrowRight} />
    </button>
</a>


      </section>
 {/* Download Our App Section */}
 <section className="download-app-section">
          <h3>Download Our App</h3>
          <p>Get the Karanki Matrimony app for the best matchmaking experience.</p>
          <a href="https://play.google.com/store/apps/details?id=com.epnosys.karanki" target="_blank" rel="noopener noreferrer">
            <img src={googlePlayIcon} alt="Download on Google Play" className="google-play-button" />
          </a>
        </section>
     

      <div className="review-container">
      <img 
              src={feedback} 
              alt="Feedback Icon" 
              className="feedcack-icon" 
            />
      <h2>Your Feedback Matters!</h2>
      <p>We value your feedback! Please take a moment to rate our service and share your thoughts.</p>
      <button className="rate-us-button" onClick={handleRateUs}>
        <FaStar style={{ marginRight: '8px' }} />
        Rate Us
      </button>
    </div>
    <div className="whatsapp-button" onClick={handleWhatsApp}>
  <FontAwesomeIcon icon={faWhatsapp} size="2x" />
  <span className="whatsapp-label">WhatsApp Us</span>
</div>

    </div>
    
    <Footer/>
    </div>
  );
};

export default Main;
