import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import './Styles/MatchingPreferences.css';
import Navbar from './Navbar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit ,faSave, faTimes} from '@fortawesome/free-solid-svg-icons';


const professionSectors = [
  "Government",
  "Private",
  "Business",
  "Not Working",
  "All",
];

const residencyTypes = [
  "NRI",
  "India"
];

const states = [
  "Andhra Pradesh",
  "Telangana",
];

const UserProfilePage = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
          setFormData(userDoc.data());
        }
        setLoading(false);
      } else {
        setUser(null);
        setUserData(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, firestore]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const userDocRef = doc(firestore, 'users', user.uid);
    await updateDoc(userDocRef, formData);
    setUserData(formData);
    setIsEditing(false);
    toast.success('Profile updated successfully!');
  };

  const handleCancel = () => {
    setFormData(userData);
    setIsEditing(false);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (!user) {
    return <p>No user logged in...</p>;
  }

  return (
    <div>
      <Navbar />
      <div className="user-profile-container">
        <h2>Matching Preference Info</h2>
        {userData ? (
          <div className="user-profile-details">
            {isEditing ? (
              <>
                <div className="form-group">
                  <label className="form-label">Partner Preference Type</label>
                  <select
                    name="partnerPreferenceType"
                    className="form-select"
                    value={formData.partnerPreferenceType || ''}
                    onChange={handleChange}
                  >
                    <option value="">Select Preference Type</option>
                    {residencyTypes.map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>

                {formData.partnerPreferenceType !== 'NRI' && (
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <select
                      name="partnerstate"
                      className="form-select"
                      value={formData.partnerstate || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select State</option>
                      {states.map(state => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="form-group">
                  <label className="form-label">Profession Sector</label>
                  <select
                    name="partnerprofessionSector"
                    className="form-select"
                    value={formData.partnerprofessionSector || ''}
                    onChange={handleChange}
                  >
                    <option value="">Select Profession Sector</option>
                    {professionSectors.map(sector => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-actions">
                <button className="btn btn-save" onClick={handleSave}>
        <FontAwesomeIcon icon={faSave} /> Save
      </button>
      <button className="btn btn-cancel" onClick={handleCancel}>
        <FontAwesomeIcon icon={faTimes} /> Cancel
      </button>
                </div>
              </>
            ) : (
              <>
                <p><strong>Partner Preference Type:</strong> {userData.partnerPreferenceType}</p>
                {userData.partnerPreferenceType !== 'NRI' && (
                  <p><strong>State:</strong> {userData.partnerstate}</p>
                )}
                <p><strong>Profession Sector:</strong> {userData.partnerprofessionSector}</p>
                <button className="btn btn-edit" onClick={() => setIsEditing(true)}>
      <FontAwesomeIcon icon={faEdit} /> Edit
    </button>
              </>
            )}
          </div>
        ) : (
          <p>No user data available</p>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserProfilePage;
