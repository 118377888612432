import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import './Styles/SubscriptionPage.css'; 
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Modal from '../Components/Success'; // Import the Modal component
import PaymentDetailModal from '../Components/PaymentDetailModal'; // New modal for payment details
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons'; // Choose an appropriate icon

import Navbar from './Navbar';
import Footer from './Footer';
import { FaHistory } from 'react-icons/fa';
import { FaCrown } from 'react-icons/fa';

const SubscriptionPage = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentPlan, setCurrentPlan] = useState('Free');
    const [paymentDetails, setPaymentDetails] = useState({ amount: 0, transactionId: '', expiryDate: '' });
    const [isPaidMember, setIsPaidMember] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [modalMessage, setModalMessage] = useState(''); // State for modal message
    const [paymentDetailModalOpen, setPaymentDetailModalOpen] = useState(false); // State for payment detail modal visibility
    const [selectedPayment, setSelectedPayment] = useState(null); // State for selected payment

    const PREMIUM_PLAN_AMOUNT = 999; 
    const GST_PERCENTAGE = 18; 
    const PLAN_DURATION_DAYS = 365; // Duration of the premium plan in days

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const userRef = doc(db, 'users', user.uid);
                    const userDoc = await getDoc(userRef);
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setCurrentPlan(userData.isPaidMember ? 'Premium' : 'Free');
                        setIsPaidMember(userData.isPaidMember);
                        setPaymentDetails({
                            amount: userData.paymentAmount || 0,
                            transactionId: userData.transactionId || '',
                            expiryDate: userData.expiryDate || ''
                        });
                        setPaymentHistory(userData.paymentHistory || []);
                        setCurrentUser({ ...user, ...userData });
                    }
                } catch (fetchError) {
                    console.error('Error fetching user data:', fetchError);
                    setError('Failed to fetch user data. Please try again.');
                }
            } else {
                setCurrentUser(null);
                setCurrentPlan('Free');
                setIsPaidMember(false);
                setPaymentDetails({ amount: 0, transactionId: '', expiryDate: '' });
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleUpgrade = () => {
        handlePayment(PREMIUM_PLAN_AMOUNT);
    };

    const handlePayment = (amount) => {
        const gstAmount = (amount * GST_PERCENTAGE) / 100;
        const totalAmount = amount + gstAmount;

        const options = {
            key: 'rzp_test_jZllf5L14roJi4',
            amount: totalAmount * 100,
            currency: 'INR',
            name: 'Matrimonial Site',
            description: 'Upgrade to Premium Plan',
            handler: async function (response) {
                const transactionId = response.razorpay_payment_id;
                const currentDate = new Date();
                const expiryDate = new Date(currentDate);
                expiryDate.setDate(currentDate.getDate() + PLAN_DURATION_DAYS); // Set expiry date to one year from now

                setCurrentPlan('Premium');
                setPaymentDetails({ 
                    amount: totalAmount, 
                    transactionId,
                    expiryDate: expiryDate.toLocaleDateString('en-IN') // Format expiry date
                });
                setIsPaidMember(true);
                setError('');
                setModalMessage(`Your payment of ₹${totalAmount} was successful!`);
                setModalOpen(true); 

                if (currentUser) {
                    try {
                        const userRef = doc(db, 'users', currentUser.uid);
                        const paymentRecord = {
                            amount: totalAmount,
                            transactionId,
                            paymentDate: currentDate.toLocaleDateString('en-IN'), // Format payment date
                            status: 'Success', // Mark status as Success
                            expiryDate: expiryDate.toLocaleDateString('en-IN'), // Save expiry date here
                        };
                        await setDoc(userRef, {
                            isPaidMember: true,
                            paymentAmount: totalAmount,
                            transactionId,
                            expiryDate: expiryDate.toLocaleDateString('en-IN'), // Save expiry date in user data
                            paymentHistory: [...paymentHistory, paymentRecord],
                        }, { merge: true });
                    } catch (saveError) {
                        console.error('Error saving payment details:', saveError);
                        setError('Failed to save payment details. Please contact support.');
                    }
                }
            },
            prefill: {
                name: currentUser ? currentUser.fullName || 'Your Name' : 'Your Name',
                email: currentUser ? currentUser.email : 'you@example.com',
                contact: currentUser && currentUser.mobileNumber ? currentUser.mobileNumber : '9999999999',
            },
            theme: {
                color: '#F37254'
            },
            modal: {
                ondismiss: function () {
                    setError('Payment process was dismissed. Please try again.');
                }
            }
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    const handleViewPaymentDetails = (record) => {
        setSelectedPayment(record);
        setPaymentDetailModalOpen(true);
    };

    if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

    return (
        <div>
            <Navbar/>
        <div className="subscription-page">
            <h2 className="sub-title">Subscription</h2>

            {currentUser && (
                <div className="user-info-section">
                    <h3>My Account</h3>
                    <p><strong>Name:</strong> {currentUser.fullName || 'Not provided'}</p>
                    <p><strong>Mobile Number:</strong> {currentUser.mobileNumber || 'Not provided'}</p>
                </div>
            )}

<div className="current-plan-section">
            <h3>
                Current Plan: <span className="current-plan">{currentPlan}</span>
            </h3>
            {isPaidMember && (
                <div className="payment-details premium-card">
                <div className="premium-badge">
                    <p className="premium-membership"><span className="badge">
            <FontAwesomeIcon icon={faStar} style={{ marginRight: '5px' }} /> {/* Add icon with margin */}
            Premium Member
        </span></p>
                </div>
                <p>Enjoy unlimited access to all premium features and priority support. Your premium benefits are valid until <strong><span className="expiry-date">{paymentDetails.expiryDate}</span></strong>.</p>
                
            </div>
            
            )}
        </div>

            {/* Only show the premium plan for upgrading */}
            {!isPaidMember && (
                <div className="upgrade-section">
                    <h3 className="available-plans-title">
                <FaCrown style={{ marginRight: '10px', color: 'gold' }} />
                Upgrade to Premium Plan
            </h3>
                    <p>Premium Plan: ₹{PREMIUM_PLAN_AMOUNT} (including GST)</p>
                    <div className="button-gro">
                        <button className="upgrade-bun" onClick={handleUpgrade}>Upgrade to Premium</button>
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            )}

            {/* Payment History Section with Card Layout */}
            {paymentHistory.length > 0 && (
                <div className="payment-history">
                   <h3 className="payment-history-title">
                <FaHistory style={{ marginRight: '10px' }} />
                Payment History
            </h3>
                    <div className="payment-history-cards">
                        {paymentHistory
                            .sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate)) // Sort by date descending
                            .map((record, index) => (
                                <div className="payment-history-card" key={index}>
                                    <p><strong>Paid Date:</strong> {record.paymentDate}</p>
                                    <p><strong>Status:</strong> {record.status}</p>
                                    <button onClick={() => handleViewPaymentDetails(record)}>View Details</button>
                                </div>
                            ))}
                    </div>
                </div>
            )}

            {/* Modal for payment success message */}
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} message={modalMessage} />
            
            {/* Modal for payment details */}
            <PaymentDetailModal 
                isOpen={paymentDetailModalOpen} 
                onClose={() => setPaymentDetailModalOpen(false)} 
                paymentRecord={selectedPayment} 
            />
        </div>
        <Footer/>
        </div>
    );
};

export default SubscriptionPage;
