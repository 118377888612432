import React from "react";
import "./Styles/TermsConditions.css"; // Ensure you have the corresponding CSS file
import Footer from "./Footer";
import MainNav from './MainNav';
const TermsConditions = () => {
  return (
    <div>
      <MainNav/>
    <div className="terms-container">
      <div className="terms-header">
        <h1>Terms & Conditions</h1>
      </div>

      

      <div className="terms-section">
        <div className="section-header">
          <h2>1. Introduction</h2>
        </div>
        <div className="section-content">
          <p>
            Welcome to Karanki Matrimony. By accessing or using our platform, you agree to be bound by the terms and conditions outlined in this agreement. If you do not agree with these terms, you should not use our services.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>2. User Eligibility</h2>
        </div>
        <div className="section-content">
          <p>
            To register and use Karanki Matrimony, users must be at least 18 years old. By creating an account, you confirm that you meet this age requirement. Karanki Matrimony is intended only for individuals who are legally eligible for marriage under the applicable laws.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>3. Account Responsibilities</h2>
        </div>
        <div className="section-content">
          <p>
            Users are responsible for maintaining the confidentiality of their account login credentials. You are solely responsible for all activities that occur under your account, and you must notify us immediately if you suspect any unauthorized access or use of your account.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>4. Privacy Policy</h2>
        </div>
        <div className="section-content">
          <p>
            Your privacy is important to us. Please review our <a href="/privacy-policy">Privacy Policy</a> to understand how we collect, use, and protect your personal information.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>5. User Conduct</h2>
        </div>
        <div className="section-content">
          <p>
            You agree not to use the platform for any unlawful or harmful purpose. This includes, but is not limited to:
          </p>
          <ul>
            <li>Harassing, abusing, or intimidating any other users.</li>
            <li>Impersonating any person or entity, or misrepresenting your affiliation with a person or entity.</li>
            <li>Uploading or sharing content that is offensive, defamatory, or violates the rights of others.</li>
            <li>Engaging in activities that are harmful to the platform, including spamming or transmitting viruses.</li>
          </ul>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>6. Termination of Accounts</h2>
        </div>
        <div className="section-content">
          <p>
            Karanki Matrimony reserves the right to terminate or suspend any user account without prior notice if a violation of these terms is detected, or if the account activity is found to be inappropriate or harmful to other users.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>7. Limitation of Liability</h2>
        </div>
        <div className="section-content">
          <p>
            Karanki Matrimony will not be liable for any damages resulting from the use or inability to use the platform. We are not responsible for the accuracy, completeness, or reliability of any content or profiles on the platform.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>8. Changes to Terms & Conditions</h2>
        </div>
        <div className="section-content">
          <p>
            We reserve the right to update or modify these terms at any time. Users will be notified of any significant changes, and continued use of the platform after modifications implies acceptance of the revised terms.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <div className="section-header">
          <h2>9. Contact Information</h2>
        </div>
        <div className="section-content">
          <p>
            If you have any questions regarding these terms or the services provided by Karanki Matrimony, please <a href="/contact-us">Contact Us</a>.
          </p>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default TermsConditions;
