
import React from 'react';
import '../Styles/Register.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
// Profession options array
const professionOptions = [
  "Accountant (CA)",
  "Aeronautical engineer",
  "Auto Ancillary",
  "Banking",
  "Biomedical engineer",
  "Cook/Chief",
  "Computer Engineer",
  "Civil Engineer",
  "Chemical Engineer",
  "Doctor",
  "Electrical Engineer",
  "Farmer",
  "Hotel",
  "Hospitality",
  "Human Resources",
  "Lawyer",
  "Lecturer",
  "Mechanical Engineer",
  "Marine Engineer",
  "Nuclear Engineer",
  "Police Officer",
  "Pharmacy",
  "Software Engineer",
  "Soldier",
  "Scientist",
  "Teacher"
];


// Profession sector options
const professionSectors = [
  "Government",
  "Private",
  "Business",
  "Not Working"
];


const Step3 = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="step-container">
     <h2 className="step-title">
  <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '8px' }} />
  Step 3: Education and Profession
</h2>
      
      {/* Education Dropdown */}
      <label className="form-label">Education</label>
      <input
        type="text"
        name="education"
        className="form-input"
        value={formData.education || ''}
        onChange={handleInputChange}
         placeholder="Enter your education"
       
        required
      />

      {/* College Name Input */}
      <label className="form-label">College Name</label>
      <input
        type="text"
        name="collegeName"
        className="form-input"
        value={formData.collegeName || ''}
        onChange={handleInputChange}
        placeholder="Enter College Name"
        required
      />

    

    {/* Profession Dropdown */}
<label className="form-label">Profession</label>
<select
  name="profession"
  className="form-select"
  value={formData.profession || ''}
  onChange={handleInputChange}
  required
>
  <option value="">Select Profession</option>
  {professionOptions.map((profession) => (
    <option key={profession} value={profession}>
      {profession}
    </option>
  ))}
  <option value="Other">Other</option>
</select>

{/* If 'Other' is selected, show a manual input field */}
{formData.profession === 'Other' && (
  <div className="form-label">
    <label className="form-label">Enter Profession</label>
    <input
      type="text"
      name="otherProfession"
      className="form-input"
      value={formData.otherProfession || ''}
      onChange={handleInputChange}
      placeholder="Enter Profession manually"
      required
    />
  </div>
)}


  {/* Profession Sector Dropdown */}
  <label className="form-label">Profession Sector</label>
      <select
        name="professionSector"
        className="form-select"
        value={formData.professionSector || ''}
        onChange={handleInputChange}
        required
      >
        <option value="">Select Profession Sector</option>
        {professionSectors.map((sector) => (
          <option key={sector} value={sector}>
            {sector}
          </option>
        ))}
      </select>
      

      <label className="form-label">Company Name</label>
      <input
        type="text"
        name="companyName"
        className="form-input"
        value={formData.companyName || ''}
        onChange={handleInputChange}
       
        required
      />


<label className="form-label">Working Place</label>
      <input
        type="text"
        name="workingPlace"
        className="form-input"
        value={formData.workingPlace || ''}
        onChange={handleInputChange}
        
        required
      />

<label className="form-label">Annual Income</label>
      <input
        type="text"
        name="income"
        className="form-input"
        value={formData.income || ''}
        onChange={handleInputChange}
        placeholder="Ex : 10,00,000 per annum "
        required
      />


    </div>
    
  );
};

export default Step3;

