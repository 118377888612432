// src/components/FAQ.js
import React, { useState } from 'react';
import './Styles/FAQ.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';
const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What is Karanki Matrimony?",
            answer: "Karanki Matrimony is an online platform designed to connect individuals for marriage based on their preferences, backgrounds, and values."
        },
        {
            question: "How do I register?",
            answer: "You can register by filling out our online registration form, providing the necessary details, and submitting your profile."
        },
        {
            question: "Is there a fee for using the services?",
            answer: "Yes, we offer various subscription plans that provide access to additional features and benefits on our platform."
        },
        {
            question: "Can I delete my profile?",
            answer: "Yes, you can delete your profile anytime via customer support. Please note that this action is irreversible."
        },
        {
            question: "How do I contact customer support?",
            answer: "You can reach out to our customer support team through the 'Customer support' page or directly via our helpline number."
        },
    ];

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div>
            
        
        <div className="faq-container">
        <Link to="/" className="register-button">
      Go to Home
    </Link>
            <h1 className="faq-heading">Frequently Asked Questions</h1>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={() => toggleFAQ(index)}>
                            <h2>{faq.question}</h2>
                            <span className="faq-toggle">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
                    </div>
                ))}
            </div>
        </div>
        <Footer/>
        </div>


    );
};

export default FAQ;
