// src/components/ContactUs.js
import React from 'react';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import './Styles/ContactUs.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ContactUs = () => {
    return (
        <div>
            <Navbar/>
       
        <div className="contact-us-container">
            <h1 className="contact-us-heading">Contact Us</h1>
            <p className="contact-us-description">If you have any questions, feel free to reach out!</p>
            <div className="contact-details">
                <div className="contact-item">
                    <FaPhoneAlt className="icon phone-icon" />
                    <h2 className="contact-item-heading">Call Us</h2>
                    <p className="contact-item-description">Customer support</p>
                    <a href="tel:+919912349925" className="button call-button">
      <FontAwesomeIcon icon={faPhone} /> Call Now
    </a>
                </div>
                <div className="contact-item">
                    <FaWhatsapp className="icon chat-icon" />
                    <h2 className="contact-item-heading">Chat with Us</h2>
                    <p className="contact-item-description">Reach us via WhatsApp</p>
                    <a 
      href="https://wa.me/919912349925" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="button chat-button"
    >
      <FontAwesomeIcon icon={faWhatsapp} /> Chat Now
    </a>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
    );
};

export default ContactUs;
