import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import Navbar from '../Components/Navbar';
import './Styles/RecentlyViewed.css';

const RecentlyViewed = () => {
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchRecentlyViewed(user.uid);
      } else {
        toast.error('You need to be logged in to view this page.');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchRecentlyViewed = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const viewedUsers = userData.recentlyViewed || [];

        const usersPromises = viewedUsers.map(async (userId) => {
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
          return userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null;
        });

        const viewedUsersData = await Promise.all(usersPromises);
        setRecentlyViewed(viewedUsersData.filter(user => user !== null).reverse());
      } else {
        toast.error('User data not found.');
      }
    } catch (error) {
      console.error('Error fetching recently viewed profiles:', error);
      toast.error('Failed to fetch recently viewed profiles. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleProfileClick = (userId) => {
    navigate(`/user/${userId}`);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="recently-viewed-wrapper">
        <div className="recently-viewed-header">
          <h1>Recently Viewed Profiles</h1>
        </div>
        <div className="profiles-grid">
          {recentlyViewed.length === 0 ? (
            <p>No recently viewed profiles.</p>
          ) : (
            recentlyViewed.map((user) => (
              <div key={user.id} className="profile-card" onClick={() => handleProfileClick(user.id)}>
                <div className="user-card">
  <div className="user-photo-container">
    {user.photos && user.photos.length > 0 ? (
      <img src={user.photos[0]} alt={`${user.fullName}'s profile`} className="user-photo" />
    ) : (
      <div className="user-photo-placeholder">No Photo</div>
    )}
  </div>
  <p className="user-name">{user.fullName}</p>
  <div className="user-details">
    <p className="user"><strong>Date of Birth:</strong> {user.dateOfBirth}</p>
    <p className="user"><strong>Height:</strong> {user.height}</p>
    <p className="user"><strong>Education:</strong> {user.education}</p>
    <p className="user"><strong>Native State:</strong> {user.state}</p>
  </div>
</div>

              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentlyViewed;
