// Step1.js
import '../Styles/Register.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
const heights = [
  "4'6\"", "4'7\"", "4'8\"", "4'9\"", "4'10\"", "4'11\"",
  "5'0\"", "5'1\"", "5'2\"", "5'3\"", "5'4\"", "5'5\"",
  "5'6\"", "5'7\"", "5'8\"", "5'9\"", "5'10\"", "5'11\"",
  "6'0\"", "6'1\"", "6'2\"", "6'3\"", "6'4\"", "6'5\"",
  "6'6\"", "6'7\"", "6'8\"", "6'9\"", "6'10\"", "6'11\""
];

const complexions = ["Wheatish", "Fair", "White", "Red", "Brown", "Dark"];
const religions = ["Hindu", "Converted Christian"];
const genderOptions = ["Male", "Female"];
const rashis = [
  "Aries (మేషం)", "Taurus (వృషభం)", "Gemini (మిథునం)",
  "Cancer (కర్కాటకం)", "Leo (సింహం)", "Virgo (కన్యా)",
  "Libra (తులా)", "Scorpio (వృశ్చికం)", "Sagittarius (ధనస్సు)",
  "Capricorn (మకరం)", "Aquarius (కుంభం)", "Pisces (మీనం)"
];

const castes = [
  "Gowda", "Setti Balija","Reddy", "Kamma", "Kapu","Vysya (Vaishya)","Mala","Madiga"

];

const Step1 = ({ formData, setFormData }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

   

    return (
      <div className="step-container">
       <h2 className="step-title">
  <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
  Step 1: Personal Information
</h2>
        
       
        <label className="form-label">Residence Type</label>
        <select name="residenceType" className="form-select" value={formData.residenceType} onChange={handleInputChange}>
          <option value="">Select Residence Type</option>
          <option value="India">India</option>
          <option value="NRI">NRI</option>
        </select>

        {formData.residenceType === 'NRI' && (
          <>
          <label className="form-label">Country</label>
          <select name="country" className="form-select" value={formData.country} onChange={handleInputChange}>
  <option value="">Select Country</option>
  <option value="Australia">Australia</option>
  <option value="USA">USA</option>
  <option value="Canada">Canada</option>
  <option value="UK">UK</option>
  <option value="UAE">United Arab Emirates</option>
  <option value="Singapore">Singapore</option>
  <option value="Saudi Arabia">Saudi Arabia</option>
  <option value="New Zealand">New Zealand</option>
  <option value="Malaysia">Malaysia</option>
  <option value="Germany">Germany</option>
  <option value="South Africa">South Africa</option>
  <option value="Qatar">Qatar</option>
  <option value="Kuwait">Kuwait</option>
  <option value="France">France</option>
  <option value="Netherlands">Netherlands</option>
  <option value="Sweden">Sweden</option>
  <option value="Switzerland">Switzerland</option>
  <option value="Italy">Italy</option>
  <option value="Japan">Japan</option>
  <option value="South Korea">South Korea</option>
  <option value="Other">Other</option>
</select>



            {formData.country === 'Other' && (
              <>
                <label className="form-label">Other Country</label>
                <input type="text" name="otherCountry" className="form-input" value={formData.otherCountry} onChange={handleInputChange} placeholder="Enter Country Name"  />
              </>
            )}
          </>
        )}

<label className="form-label">Full Name</label>
<input type="text" name="fullName" className="form-input" value={formData.fullName} onChange={handleInputChange} placeholder="Enter Full Name" required />

        <label className="form-label">Gender</label>
        <select name="gender" className="form-select" value={formData.gender} onChange={handleInputChange}>
          <option value="">Select Gender</option>
          {genderOptions.map(gender => (
            <option key={gender} value={gender}>{gender}</option>
          ))}
        </select>

        <label className="form-label">Marital Status</label>
        <select name="maritalStatus" className="form-select" value={formData.maritalStatus} onChange={handleInputChange}>
          <option value="">Select Marital Status</option>
          <option value="Single">Single</option>
          <option value="Divorced">Divorced</option>
          <option value="Widowed">Spouse lost</option>
        </select>

        <label className="form-label">Date of Birth</label>
        <input type="text" name="dateOfBirth" className="form-input" value={formData.dateOfBirth} onChange={handleInputChange} placeholder="DD/MM/YYYY"/>

        <label className="form-label">Time of Birth</label>
        <input type="text" name="timeOfBirth" className="form-input" value={formData.timeOfBirth} onChange={handleInputChange} placeholder="e.g., 10:30 AM" />

        <label className="form-label">Rashi</label>
        <select name="rashi" className="form-select" value={formData.rashi} onChange={handleInputChange}>
          <option value="">Select Rashi</option>
          {rashis.map(rashi => (
            <option key={rashi} value={rashi}>{rashi}</option>
          ))}
        </select>

        <label className="form-label">Nakshatram</label>
        <input type="text" name="nakshatram" className="form-input" value={formData.nakshatram} onChange={handleInputChange} placeholder="e.g., Ashwini" />

        <label className="form-label">Gotram</label>
        <input type="text" name="gotram" className="form-input" value={formData.gotram} onChange={handleInputChange} />

        <label className="form-label">Birth Place</label>
        <input type="text" name="birthPlace" className="form-input" value={formData.birthPlace} onChange={handleInputChange} />

        <label className="form-label">Height</label>
        <select name="height" className="form-select" value={formData.height} onChange={handleInputChange}>
          <option value="">Select Height</option>
          {heights.map(height => (
            <option key={height} value={height}>{height}</option>
          ))}
        </select>

        <label className="form-label">Complexion</label>
        <select name="complexion" className="form-select" value={formData.complexion} onChange={handleInputChange}>
          <option value="">Select Complexion</option>
          {complexions.map(complexion => (
            <option key={complexion} value={complexion}>{complexion}</option>
          ))}
        </select>

        <label className="form-label">Caste</label>
        <select name="caste" className="form-select" value={formData.caste} onChange={handleInputChange}>
          <option value="">Select Caste</option>
          {castes.map(caste => (
            <option key={caste} value={caste}>{caste}</option>
          ))}
        </select>

        <label className="form-label">Religion</label>
        <select name="religion" className="form-select" value={formData.religion} onChange={handleInputChange}>
          <option value="">Select Religion</option>
          {religions.map(religion => (
            <option key={religion} value={religion}>{religion}</option>
          ))}
        </select>

        <label className="form-label">Mobile Number</label>
        <input type="tel" name="mobileNumber" className="form-input" value={formData.mobileNumber || ''} onChange={handleInputChange} placeholder="Enter Mobile Number" required />

        <label className="form-label">Email * Please Enter correct Email ID *</label>
        <input type="email" name="email" className="form-input" value={formData.email || ''} onChange={handleInputChange} placeholder="Email ID" required />

        <label className="form-label">Create a Password</label>
        <input type="password" name="password" className="form-input" value={formData.password || ''} onChange={handleInputChange} placeholder="Password" required />
      </div>
    );
};

export default Step1;
