// src/Components/UserDetail.js

import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase'; // Ensure 'auth' is exported from your firebase config
import { doc, getDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import PhotoModal from '../Components/PhotoModal'; // Import the modal
import './Styles/UserDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faUser ,faUsers, faGraduationCap, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons'; // Import the eye icon






// Import the default "No Photo" image
import noPhotoImage from '../Assets/nophoto.png';

const UserDetail = () => {
  const { userId } = useParams(); // ID of the matched user
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null); // Data of the matched user
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [isPaidMember, setIsPaidMember] = useState(false); // Track if the logged-in user is a paid member
  const [currentUser, setCurrentUser] = useState(null); // Current logged-in user
  const isLoading = true; 
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60); // 60 seconds countdown

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
       
        // Fetch current user's details
        try {
          const currentUserDocRef = doc(db, 'users', user.uid);
          const currentUserDoc = await getDoc(currentUserDocRef);
          if (currentUserDoc.exists()) {
            const currentUserData = currentUserDoc.data();
            setIsPaidMember(currentUserData.isPaidMember);
           
          } else {
            console.error('Current user data not found in Firestore.');
          }
        } catch (error) {
          console.error('Error fetching current user details:', error);
        }
      } else {
        console.error('No user is currently logged in.');
        setIsPaidMember(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data());
         
        } else {
          console.error('Matched user not found in Firestore.');
        }
      } catch (error) {
        console.error('Error fetching matched user details:', error);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch matched user details if the user is authenticated
    if (currentUser) {
      fetchUserDetails();
    } else {
      setLoading(false);
    }
  }, [userId, currentUser]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="user-detail-page">
        <Navbar />
        <div className="user-detail-content">
      <p>Please log in to view user details. <Link to="/">Click here to login</Link></p>
    </div>
      </div>
    );
  }
  const handleShowContactDetails = () => {
    setShowContactDetails(true);
    // Start the countdown timer
    setTimeLeft(60); // Reset time left to 60 seconds
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0; // Stop countdown at 0
        }
        return prevTime - 1; // Decrement time left
      });
    }, 1000); // Update every second
  };
  return (
    <div className="user-detail-page">
      <Navbar />
      <div className="user-detail-content">
       
        
        {userData ? (
          <div className="profile-container">
             <button className="back-button" onClick={handleBackClick}>
  <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
  Back
</button>

            <div className="photo-container" onClick={openModal}>
              <div className="medium-user-photo">
                {userData.photos && userData.photos.length > 0 ? (
                  <img
                    src={userData.photos[0]}
                    alt={`${userData.fullName}'s profile`}
                    className="medium-photo"
                    onError={(e) => { e.target.onerror = null; e.target.src = noPhotoImage; }}
                  />
                ) : (
                  <img
                    src={noPhotoImage}
                    alt="Profile not available"
                    className="medium-photo"
                  />
                )}
              </div>
            </div>

            {isModalOpen && (
              <PhotoModal
                photoUrl={userData.photos && userData.photos.length > 0 ? userData.photos[0] : noPhotoImage}
                onClose={closeModal}
              />
            )}

            <h1 className="profile-name">{userData.fullName}</h1>
            
            <div className="profile-info">
              {/* Personal Information Section */}
              <div className="profile-info-section">
              <h2>
      <FontAwesomeIcon icon={faUser} /> Personal Information
    </h2>
    <p><strong>Residence Type:</strong> {userData.residenceType}</p>
{userData.residenceType === 'NRI' && (
    <p><strong>Country:</strong> {userData.country}</p>
)}

                <p><strong>Full Name:</strong> {userData.fullName}</p>
                <p><strong>Gender:</strong> {userData.gender}</p>
                <p><strong>Marital Status:</strong> {userData.maritalStatus}</p>
                <p><strong>Date of Birth:</strong> {userData.dateOfBirth}</p>
                <p><strong>Time of Birth:</strong> {userData.timeOfBirth}</p>
                <p><strong>Complexion:</strong> {userData.complexion}</p>
                <p><strong>Rashi:</strong> {userData.rashi}</p>
                <p><strong>Nakshatram:</strong> {userData.nakshatram}</p>
                <p><strong>Gotram:</strong> {userData.gotram}</p>
                <p><strong>Birth Place:</strong> {userData.birthPlace}</p>
                <p><strong>Height:</strong> {userData.height}</p>
              </div>

              {/* Family Information Section */}
              <div className="profile-info-section">
              <h2>
        <FontAwesomeIcon icon={faUsers} /> Family Information
      </h2>
                <p><strong>Father Name:</strong> {userData.fatherName}</p>
                <p><strong>Father Profession:</strong> {userData.fatherProfession}</p>
                <p><strong>Father Designation:</strong> {userData.fatherDesignation}</p>
                <p><strong>Mother Name:</strong> {userData.motherName}</p>
                <p><strong>Mother Surname:</strong> {userData.motherSurname}</p>
                <p><strong>Mother Profession:</strong> {userData.motherProfession}</p>
                <p><strong>Number of Brothers:</strong> {userData.numberOfBrothers}</p>
                <p><strong>Number of Sisters:</strong> {userData.numberOfSisters}</p>
                <p><strong>Financial Status:</strong> {userData.financialStatus}</p>
              </div>

              {/* Education/Professional Information Section */}
              <div className="profile-info-section">
              <h2>
        <FontAwesomeIcon icon={faGraduationCap} /> Education/Professional Information
      </h2>
                <p><strong>Education:</strong> {userData.education}</p>
                <p><strong>College Name:</strong> {userData.collegeName}</p>
                <p><strong>Profession:</strong> 
                  {userData.profession === 'Other' ? userData.otherProfession : userData.profession}
                </p>
                <p><strong>Profession Sector:</strong> {userData.professionSector}</p>
                <p><strong>Company Name:</strong> {userData.companyName}</p>
                <p><strong>Working Place:</strong> {userData.workingPlace}</p>
                <p><strong>Annual Income:</strong> {userData.income}</p>
              </div>

              {/* Address Information Section */}
              <div className="profile-info-section">
              <h2>
        <FontAwesomeIcon icon={faMapMarkerAlt} /> Address Information
      </h2>
                <p><strong>Native State:</strong> {userData.state}</p>
                <p><strong>District:</strong> {userData.nativePlaceDistrict}</p>
                
                {userData.nativeStateOther && (
                  <p><strong>Native State (Other):</strong> {userData.nativeStateOther}</p>
                )}
                
                <p><strong>Native Place:</strong> {userData.place}</p>
                <p><strong>Present Residence:</strong> {userData.presentResidence}</p>
              </div>

              {/* Contact Information Section */}
              <div className="profile-info-section">
              <h2>
        <FontAwesomeIcon icon={faPhone} /> Contact Information
      </h2>
      {isPaidMember ? (
        <div>
            <button className="show-contact-button" onClick={handleShowContactDetails}>
      <FontAwesomeIcon icon={faEye} /> {/* Add the icon here */}
      Show Contact Details
    </button>

          {showContactDetails && (
            <div>
              {timeLeft > 0 ? (
                <div className="time-display">
                  <span className="time">{timeLeft}s remaining</span>
                </div>
              ) : (
                <div>
                  <p>
                    <strong>Contact Number:</strong> {userData.mobileNumber || 'Not Provided'}
                  </p>
                  <p>
                    <strong>Alternative Mobile Number:</strong> {userData.altMobileNumber || 'Not Provided'}
                  </p>
                  <p>
                    <strong>Email:</strong> {userData.email || 'Not Provided'}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>Contact details are available for paid members only.</p>
        </div>
      )}
              </div>
            </div>
          </div>
        ) : (
          <div className="user-details">
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading user details...</p>
        </div>
      ) : (
        <p>User details not found.</p>
      )}
    </div>
        )}
      </div>
    </div>
  );
};

export default UserDetail;
