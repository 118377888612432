import { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { onAuthStateChanged } from 'firebase/auth';
import './Styles/Profile.css';
import Navbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { FaGraduationCap } from 'react-icons/fa';
import { FaUsers,FaUser } from 'react-icons/fa'; 
import { faPen ,faSave, faTimes } from '@fortawesome/free-solid-svg-icons';


const heights = ['4\'0"', '4\'1"', '4\'2"', '4\'3"', '4\'4"', '4\'5"', '4\'6"', '4\'7"', '4\'8"', '4\'9"', '4\'10"', '4\'11"', '5\'0"', '5\'1"', '5\'2"', '5\'3"', '5\'4"', '5\'5"', '5\'6"', '5\'7"', '5\'8"', '5\'9"', '5\'10"', '5\'11"', '6\'0"', '6\'1"', '6\'2"', '6\'3"', '6\'4"', '6\'5"', '6\'6"'];
const complexions = ["Wheatish", "Fair", "White", "Red", "Brown", "Dark"];
const religions = ['Hindu', 'Converted Christian'];
const professionSectors = [
  "Government",
  "Private",
  "Business",
  "Not Working"
];
const professionOptions = [
  "Accountant (CA)",
  "Aeronautical engineer",
  "Auto Ancillary",
  "Banking",
  "Biomedical engineer",
  "Cook/Chief",
  "Computer Engineer",
  "Civil Engineer",
  "Chemical Engineer",
  "Doctor",
  "Electrical Engineer",
  "Farmer",
  "Hotel",
  "Hospitality",
  "Human Resources",
  "Lawyer",
  "Lecturer",
  "Mechanical Engineer",
  "Marine Engineer",
  "Nuclear Engineer",
  "Police Officer",
  "Pharmacy",
  "Software Engineer",
  "Soldier",
  "Scientist",
  "Teacher",
  "Other"
];
const financialStatusOptions = [
  { value: "Middle Class", label: "Middle Class" },
  { value: "Upper Class", label: "Upper Class" },
  { value: "Lower Clas", label: "Lower Class" },
  { value: "High Class", label: "High Class" }
];


const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    gender: '',
    maritalStatus: '',
    dateOfBirth: '',
    timeOfBirth: '',
    rashi: '',
    nakshatram: '',
    gotram: '',
    birthPlace: '',
    height: '',
    mobileNumber: '',
    password: '',
    fatherName: '',
    fatherProfession: '',
    fatherDesignation: '',
    motherName: '',
    motherSurname: '',
    motherProfession: '',
    altMobileNumber: '',
    numberOfBrothers: '',
    numberOfSisters: '',
    state: '',
    nativePlaceDistrict: '',
    nativeStateOther: '',
    place: '',
    presentResidence: '',
    education: '',
    collegeName: '',
    professionSector: '',
    residenceType: '',
    profession: '',
    financialStatus:'',
    workingPlace:'',
    companyName:'',
    income:''
  });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchProfile(currentUser.uid);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchProfile = async (userId) => {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProfileData(docSnap.data());
        setFormData(docSnap.data());
      } else {
        toast.error('No profile data found.');
      }
    } catch (error) {
      toast.error('Failed to fetch profile data.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProfileUpdate = async () => {
    // Validation: Check if fullName and mobileNumber are filled
    if (!formData.fullName.trim()) {
      toast.error('Full Name is required.');
      return;
    }

    if (!formData.mobileNumber.trim()) {
      toast.error('Mobile Number is required.');
      return;
    }

   

    // If profession is 'Other', ensure otherProfession is filled
    if (formData.profession === 'Other' && !formData.otherProfession.trim()) {
      toast.error('Please specify your profession.');
      return;
    }

    const docRef = doc(db, 'users', user.uid);
    try {
      await updateDoc(docRef, formData);
      toast.success('Profile updated successfully!');
      setProfileData(formData);
      setEditMode(false);
    } catch (error) {
      toast.error('Profile update failed.');
    }
  };

  if (loading) return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
  );

  const generateWhatsAppShareLink = () => {
  // Format the profile data into a message string
  const profileMessage = `
*Karanki Matrimony:*
--------------------
  *Profile Details:*

**Personal Information:**
-----------------------
Name: ${formData.fullName}
Marital Status: ${formData.maritalStatus}
Date of Birth: ${formData.dateOfBirth}
Time of Birth: ${formData.timeOfBirth}
Height: ${formData.height}
Complexion: ${formData.complexion}
Caste: ${formData.caste}
Religion: ${formData.religion}
Rashi: ${formData.rashi}
Nakshatram: ${formData.nakshatram}
Gotram: ${formData.gotram}
Birth Place: ${formData.birthPlace}
Residence Type: ${formData.residenceType}

**Family Information:**
---------------------
Father Name: ${formData.fatherName}
Father Profession: ${formData.fatherProfession}
Father Designation: ${formData.fatherDesignation}
Mother Name: ${formData.motherName}
Mother Surname: ${formData.motherSurname}
Mother Profession: ${formData.motherProfession}
Number of Brothers: ${formData.numberOfBrothers}
Number of Sisters: ${formData.numberOfSisters}
Financial Status: ${formData.financialStatus}

**Education/Professional Information:**
-------------------------------------
Education: ${formData.education}
College Name: ${formData.collegeName}
Profession: ${formData.profession === 'Other' ? formData.otherProfession : formData.profession}
Profession Sector: ${formData.professionSector}
Company Name: ${formData.companyName}
Working Place: ${formData.workingPlace}
Annual Income: ${formData.income}

**Address Information:**
----------------------
Native State: ${formData.state}
District: ${formData.nativePlaceDistrict}
${formData.nativeStateOther ? `Native State (Other): ${formData.nativeStateOther}` : ''}
Native Place: ${formData.place}
Present Residence: ${formData.presentResidence}

**Contact Information:**
----------------------
Mobile Number: ${formData.mobileNumber}
Alternate Mobile Number: ${formData.altMobileNumber}
Email : ${formData.email}
  `.trim();

  // Encode the message for URL
  const encodedMessage = encodeURIComponent(profileMessage);

  // WhatsApp share URL
  return `https://wa.me/?text=${encodedMessage}`;
};

  if (!user) return  <div className="login-message">
  <p>Please log in to view profile details. <Link to="/login">Click here to login</Link></p>
</div>

  if (!profileData) return <div>Error: No profile data available.</div>;

  return (
    <div>
      <Navbar />
      <div className="profile-container">
      <h1 className="profile-title">My Profile</h1>


        {editMode ? (
          <div className="edit-profile">
            <section className="profile-section">
              <h2>Personal Information</h2>
              <label className="form-label">Residence Type</label>
        <select name="residenceType" className="form-select" value={formData.residenceType} onChange={handleInputChange}>
          <option value="">Select Residence Type</option>
          <option value="India">India</option>
          <option value="NRI">NRI</option>
        </select>
              <label>
                Full Name:
                <input
                  name="fullName"
                  value={formData.fullName || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
             
              <label>
                Date of Birth:
                <input
                  type="text"
                  name="dateOfBirth"
                  value={formData.dateOfBirth || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                  placeholder='DD/MM/YYYY'
                />
              </label>
              <label>
                Time of Birth:
                <input
                  type="text"
                  name="timeOfBirth"
                  value={formData.timeOfBirth || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Complexion:
                <select
                  name="complexion"
                  value={formData.complexion || ''}
                  onChange={handleInputChange}
                  className="profile-select"
                >
                  <option value="">Select Complexion</option>
                  {complexions.map((complexion) => (
                    <option key={complexion} value={complexion}>{complexion}</option>
                  ))}
                </select>
              </label>
              <label>
                Height:
                <select
                  name="height"
                  value={formData.height || ''}
                  onChange={handleInputChange}
                  className="profile-select"
                >
                  <option value="">Select Height</option>
                  {heights.map((height) => (
                    <option key={height} value={height}>{height}</option>
                  ))}
                </select>
              </label>
              <label>
                Marital Status:
                <select
                  name="maritalStatus"
                  value={formData.maritalStatus || ''}
                  onChange={handleInputChange}
                  className="profile-select"
                >
                  <option value="">Select Marital Status</option>
                  <option value="Single">Single</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Married">Spouse lost</option>
                  
                </select>
              </label>
             
              <label>
                Religion:
                <select
                  name="religion"
                  value={formData.religion || ''}
                  onChange={handleInputChange}
                  className="profile-select"
                >
                  <option value="">Select Religion</option>
                  {religions.map((religion) => (
                    <option key={religion} value={religion}>{religion}</option>
                  ))}
                </select>
              </label>
             
              <label>
                Birth Place:
                <input
                  name="birthPlace"
                  value={formData.birthPlace || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>

              <label>
                Mobile Number:
                <input
                  name="mobileNumber"
                  value={formData.mobileNumber || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              
             
              
            </section>

            <section className="profile-section">
              <h2>Family Information</h2>
              <label>
                Father Name:
                <input
                  name="fatherName"
                  value={formData.fatherName || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Father Profession:
                <input
                  name="fatherProfession"
                  value={formData.fatherProfession || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Father Designation:
                <input
                  name="fatherDesignation"
                  value={formData.fatherDesignation || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Mother Name:
                <input
                  name="motherName"
                  value={formData.motherName || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Mother Surname:
                <input
                  name="motherSurname"
                  value={formData.motherSurname || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Mother Profession:
                <input
                  name="motherProfession"
                  value={formData.motherProfession || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Alternate Mobile Number:
                <input
                  name="altMobileNumber"
                  value={formData.altMobileNumber || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Number of Brothers:
                <input
                  name="numberOfBrothers"
                  value={formData.numberOfBrothers || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Number of Sisters:
                <input
                  name="numberOfSisters"
                  value={formData.numberOfSisters || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
  Native State:
  <select
    name="state"
    value={formData.state || ''}
    onChange={handleInputChange}
    className="profile-select"
  >
    <option value="">Select State</option>
    <option value="Andhra Pradesh">Andhra Pradesh</option>
    <option value="Telangana">Telangana</option>
    <option value="Other">Other</option>
  </select>
</label>

             

              <label>
                Native State (if 'Other'):
                <input
                  name="nativeStateOther"
                  value={formData.nativeStateOther || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Native Place:
                <input
                  name="place"
                  value={formData.place || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                Place of Residence:
                <input
                  name="presentResidence"
                  value={formData.presentResidence || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label className="form-label">Financial Status</label>
<select name="financialStatus" className="form-select" value={formData.financialStatus} onChange={handleInputChange}>
  <option value="">Select Financial Status</option>
  {financialStatusOptions.map((status) => (
    <option key={status.value} value={status.value}>
      {status.label}
    </option>
  ))}
</select>



              
            </section>


            <section className="profile-section">
              <h2>Education & Profession</h2>
              
              <label>
                Education:
                <input
                  name="education"
                  value={formData.education || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
              <label>
                College Name:
                <input
                  name="collegeName"
                  value={formData.collegeName || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>
             

<label>
  Profession:
  <select
    name="profession"
    value={formData.profession || ''}
    onChange={handleInputChange}
    className="profile-select"
  >
    <option value="">Select Profession</option>
    {professionOptions.map((profession) => (
      <option key={profession} value={profession}>{profession}</option>
    ))}
   
  </select>
</label>

{/* Conditionally render an input field if 'Other' is selected */}
{formData.profession === 'Other' && (
  <label>
    Please specify your profession:
    <input
      type="text"
      name="otherProfession"
      value={formData.otherProfession || ''}
      onChange={handleInputChange}
      className="profile-input"
      placeholder="Enter your profession"
    />
  </label>
)}

<label>
  Profession Sector:
  <select
    name="professionSector"
    value={formData.professionSector || ''}
    onChange={handleInputChange}
    className="profile-select"
  >
    <option value="">Select Profession Sector</option>
    {professionSectors.map((sector) => (
      <option key={sector} value={sector}>{sector}</option>
    ))}
  </select>
</label>

<label>
                Company Name:
                <input
                  name="companyName"
                  value={formData.companyName || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>

              

              <label>
                Working Place:
                <input
                  name="workingPlace"
                  value={formData.workingPlace || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                />
              </label>

              <label>
               Annual Income:
                <input
                  name="income"
                  value={formData.income || ''}
                  onChange={handleInputChange}
                  className="profile-input"
                  placeholder='Ex : 10 LPA'
                />
              </label>

            </section>

            <button onClick={handleProfileUpdate} className="update-button">
        <FontAwesomeIcon icon={faSave} /> Save
      </button>
      <button onClick={handleEditToggle} className="cancel-button">
        <FontAwesomeIcon icon={faTimes} /> Cancel
      </button>
          </div>
        ) : (
          <div className="view-profile">
            <section className="profile-section">
            <button onClick={() => window.open(generateWhatsAppShareLink(), '_blank')} className="share-button">
  <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '8px' }} />
  Share My Profile 
</button>

            <div className="personal-info-container">
              
            <h2>
                <FaUser style={{ marginRight: '8px' }} />
                Personal Information
            </h2>
  
</div>
<p><strong>Residence Type:</strong> {profileData.residenceType}</p>
{profileData.residenceType === 'NRI' && (
  <p><strong>Country:</strong> {profileData.country}</p>
)}

              <p><strong>Full Name:</strong> {profileData.fullName}</p>
              <p><strong>Gender :</strong> {profileData.gender}</p>
              <p><strong>Date of Birth:</strong> {profileData.dateOfBirth}</p>
              <p><strong>Marital Status:</strong> {profileData.maritalStatus}</p>
              <p><strong>Time of Birth:</strong> {profileData.timeOfBirth}</p>
              <p><strong>Caste:</strong> {profileData.caste}</p>
              <p><strong>Religion:</strong> {profileData.religion}</p>
              <p><strong>Complexion:</strong> {profileData.complexion}</p>
              <p><strong>Rashi:</strong> {profileData.rashi}</p>
              <p><strong>Nakshatram:</strong> {profileData.nakshatram}</p>
              <p><strong>Gotram:</strong> {profileData.gotram}</p>
              <p><strong>Birth Place:</strong> {profileData.birthPlace}</p>
              <p><strong>Height:</strong> {profileData.height}</p>
              <p><strong>Mobile Number:</strong> {profileData.mobileNumber}</p>
            </section>


            <section className="profile-section">
             
              <div className="personal-info-container">
              <h2>
            <FaGraduationCap style={{ marginRight: '8px' }} />
            Education & Profession
        </h2>
              </div>
              <p><strong>Education:</strong> {profileData.education}</p>
              <p><strong>College Name:</strong> {profileData.collegeName}</p>
              <p><strong>Profession:</strong> 
  {profileData.profession === 'Other' ? profileData.otherProfession : profileData.profession}
</p>

              <p><strong>Profession Sector:</strong> {profileData.professionSector}</p>
              <p><strong>Company Name:</strong> {profileData.companyName}</p>
              <p><strong>Working Place:</strong> {profileData.workingPlace}</p>
              <p><strong>Annual Income:</strong> {profileData.income}</p>
              
            </section>

            <section className="profile-section">
            <div className="personal-info-container">
            <h2>
                <FaUsers style={{ marginRight: '8px'}} />
                Family Information
            </h2>
</div>
              
              <p><strong>Father Name:</strong> {profileData.fatherName}</p>
              <p><strong>Father Profession:</strong> {profileData.fatherProfession}</p>
              <p><strong>Father Designation:</strong> {profileData.fatherDesignation}</p>
              <p><strong>Mother Name:</strong> {profileData.motherName}</p>
              <p><strong>Mother Surname:</strong> {profileData.motherSurname}</p>
              <p><strong>Mother Profession:</strong> {profileData.motherProfession}</p>
              <p><strong>Alternate Mobile Number:</strong> {profileData.altMobileNumber}</p>
              <p><strong>Number of Brothers:</strong> {profileData.numberOfBrothers}</p>
              <p><strong>Number of Sisters:</strong> {profileData.numberOfSisters}</p>
              <p><strong>Native State:</strong> 
  {profileData.state === 'Other' ? profileData.nativeStateOther : profileData.state}
</p>

              <p><strong>Native Place:</strong> {profileData.place}</p>
              <p><strong>Present Residence:</strong> {profileData.presentResidence}</p>
              <p><strong>Financial Status:</strong> {profileData.financialStatus}</p>
            </section>

            
            

            <button onClick={handleEditToggle} className="edit-button">
      <FontAwesomeIcon icon={faPen} /> Edit Profile
    </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
