import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import './Styles/Login.css';
import userImage from '../Assets/user.png'; // Import the image
import logo from '../Assets/karanki-web.jpg'; // Import the image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission default behavior
    if (!email || !password) {
      toast.error('Please fill in all fields.');
      return;
    }
    // Additional validation can be added here
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Logged in successfully!', {
        position: window.innerWidth <= 768 ? 'bottom-center' : 'top-right'
      });
      navigate('/home');
    } catch (error) {
      toast.error('Login failed. Please check your credentials.');
    }
  };
  
  

  return (
    <div className="login-form-container">
      
      <div className="login-form">
      <div className="login-image-containers">
        <img src={logo} alt="User Icon" className="login-images" />
      </div>
      <div className="login-image-container">
        <img src={userImage} alt="User Icon" className="login-image" />
      </div>
      <h2 className="login-heading">Login</h2>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="email"
          className="login-input"
        />
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
          className="login-input"
        />
      <button onClick={handleLogin} className="login-button">
    <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
    Login
</button>

        <div className="login-links">
          <p><Link to="/forgotpassword" className="login-link">Forgot password?</Link></p>
          <p>Don't have an account? <Link to="/register" className="login-link">Register here</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
