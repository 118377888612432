import React from 'react';
import '../Styles/Register.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
// Profession sector options
const professionSectors = [
  "Government",
  "Private",
  "Business",
  "Not Working",
  "All"

];

const Step4 = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="step-container">
     <h2 className="step-title">
  <FontAwesomeIcon icon={faHeart} style={{ marginRight: '8px' }} />
  Step 4: Partner Preference Info
</h2>

      {/* Partner Preference Type Dropdown */}
      <label className="form-label">Partner Preference Type</label>
      <select
        name="partnerPreferenceType"
        className="form-select"
        value={formData.partnerPreferenceType || ''}
        onChange={handleInputChange}
        required
      >
        <option value="">Select Preference Type</option>
        <option value="NRI">NRI</option>
        <option value="India">India</option>
      </select>

    {/* Conditional dropdown for NRI countries */}
{formData.partnerPreferenceType === 'NRI' && (
  <div className="form-group">
    <label className="form-label">Country</label>
    <select
      name="nriCountry"
      className="form-select"
      value={formData.nriCountry}
      onChange={handleInputChange}
      required
    >
      <option value="">Select Country</option>
  <option value="Australia">Australia</option>
  <option value="USA">USA</option>
  <option value="Canada">Canada</option>
  <option value="UK">UK</option>
  <option value="UAE">United Arab Emirates</option>
  <option value="Singapore">Singapore</option>
  <option value="Saudi Arabia">Saudi Arabia</option>
  <option value="New Zealand">New Zealand</option>
  <option value="Malaysia">Malaysia</option>
  <option value="Germany">Germany</option>
  <option value="South Africa">South Africa</option>
  <option value="Qatar">Qatar</option>
  <option value="Kuwait">Kuwait</option>
  <option value="France">France</option>
  <option value="Netherlands">Netherlands</option>
  <option value="Sweden">Sweden</option>
  <option value="Switzerland">Switzerland</option>
  <option value="Italy">Italy</option>
  <option value="Japan">Japan</option>
  <option value="South Korea">South Korea</option>
  <option value="Other">Other</option>
    </select>

    {/* Conditional input if 'Other' is selected */}
    {formData.nriCountry === 'Other' && (
      <div className="form-group">
        <label className="form-label">Other Country</label>
        <input
          type="text"
          name="otherCountry"
          className="form-input"
          value={formData.otherCountry || ''}
          onChange={handleInputChange}
          placeholder="Enter country"
          required
        />
      </div>
    )}
  </div>
)}


      {/* State Dropdown */}
      <label className="form-label">State</label>
      <select
        name="partnerstate"
        className="form-select"
        value={formData.partnerstate || ''}
        onChange={handleInputChange}
        required
      >
        <option value="">Select State</option>
        <option value="Andhra Pradesh">Andhra Pradesh</option>
        <option value="Telangana">Telangana</option>
        {/* Add more states as needed */}
      </select>

      {/* Profession Sector Dropdown */}
      <label className="form-label">Partner Profession Sector</label>
      <select
        name="partnerprofessionSector"
        className="form-select"
        value={formData.partnerprofessionSector || ''}
        onChange={handleInputChange}
        required
      >
        <option value="">Select Partner Profession Sector</option>
        {professionSectors.map(sector => (
          <option key={sector} value={sector}>
            {sector}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Step4;
