// src/components/PrivacyPolicy.js

import React from 'react';
import './Styles/PrivacyPolicy.css'; // Import the CSS file
import Footer from './Footer';
import MainNav from './MainNav';
const PrivacyPolicy = () => {
    return (
        <div>
            <MainNav/>
        <div className="privacy-container">
            <div className="privacy-header">
                <h1>Privacy Policy</h1>
            </div>
           

            <div className="privacy-section" id="information-collection">
                <div className="section-header">
                    <h2>1. Information We Collect</h2>
                </div>
                <div className="section-content">
                    <p>We collect the following types of information from you:</p>
                    <div className="section-list">
                        <ul>
                            <li><strong>Personal Information:</strong> Name, gender, date of birth, contact information (phone number, email address), physical attributes (height), educational details, and occupation.</li>
                            <li><strong>Family Information:</strong> Information related to your family background, parents' names, siblings, etc.</li>
                            <li><strong>Financial Information:</strong> Income details, financial status, and assets (optional).</li>
                            <li><strong>Location Data:</strong> Native place, state, district, and address (if provided).</li>
                            
                        </ul>
                    </div>
                </div>
            </div>

            <div className="privacy-section" id="information-use">
                <div className="section-header">
                    <h2>2. How We Use Your Information</h2>
                </div>
                <div className="section-content">
                    <ul>
                        <li>Create and manage your profile on Karanki Matrimony.</li>
                        <li>Facilitate matching with other users based on the details provided.</li>
                        <li>Contact you via email or phone for updates, promotions, and communication related to your membership.</li>
                        <li>Improve our platform by analyzing user behavior and feedback.</li>
                        <li>Verify your identity to protect against fraudulent activities.</li>
                    </ul>
                </div>
            </div>

            <div className="privacy-section" id="information-sharing">
                <div className="section-header">
                    <h2>3. Sharing Your Information</h2>
                </div>
                <div className="section-content">
                    <p>We only share your information under the following conditions:</p>
                    <div className="section-list">
                        <ul>
                            <li><strong>With Other Users:</strong> Your profile details may be visible to other registered users, but your contact details are only shared with your consent.</li>
                          
                            <li><strong>Legal Requirements:</strong> If required by law, we may disclose your information to comply with legal processes, enforce terms of service, or protect the rights and safety of our users.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="privacy-section" id="information-security">
                <div className="section-header">
                    <h2>4. Security of Your Information</h2>
                </div>
                <div className="section-content">
                    <p>We implement various security measures to protect your personal information. This includes encryption, secure servers, and restricted access to sensitive data. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>
                </div>
            </div>

            <div className="privacy-section" id="cookies-tracking">
                <div className="section-header">
                    <h2>5. Cookies and Tracking</h2>
                </div>
                <div className="section-content">
                    <p>We use cookies and similar tracking technologies to enhance your user experience. Cookies allow us to remember your preferences, login information, and provide personalized content. You can disable cookies in your browser settings, but this may affect your experience on our platform.</p>
                </div>
            </div>

            <div className="privacy-section" id="data-retention">
                <div className="section-header">
                    <h2>6. Data Retention</h2>
                </div>
                <div className="section-content">
                    <p>We retain your personal information for as long as your account is active or as needed to provide you with services. You may request to delete your account at any time, after which your data will be permanently removed from our records.</p>
                </div>
            </div>

            <div className="privacy-section" id="user-rights">
                <div className="section-header">
                    <h2>7. Your Rights</h2>
                </div>
                <div className="section-content">
                    <p>You have the right to:</p>
                    <div className="section-list">
                        <ul>
                            <li>Access your personal information.</li>
                            <li>Update or correct your details.</li>
                            <li>Request deletion of your account and personal data.</li>
                            <li>Withdraw consent for sharing contact details.</li>
                        </ul>
                    </div>
                    <p>To exercise any of these rights, please contact our support team at <a href="mailto:karankimatrimony2020@gmail.com">karankimatrimony2020@gmail.com</a>.</p>
                </div>
            </div>

            <div className="privacy-section" id="policy-updates">
                <div className="section-header">
                    <h2>8. Updates to This Policy</h2>
                </div>
                <div className="section-content">
                    <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you via email or by posting a notice on our platform.</p>
                </div>
            </div>

            <div className="privacy-section" id="contact-us">
                <div className="section-header">
                    <h2>9. Contact Us</h2>
                </div>
                <div className="section-content">
    <p>If you have any questions or concerns regarding our Privacy Policy, please <a href="/contact-us" className="contact-link">contact us</a></p>
</div>

            </div>
        </div>
        <Footer/>
        </div>
    );
};

export default PrivacyPolicy;
