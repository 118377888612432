import React from 'react';
import './Styles/NotFound.css'; // Import the CSS file
import notFoundImage from '../Assets/404.png'; // Import the image

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img 
        src={notFoundImage} // Use the imported image
        alt="Page Not Found" 
        className="not-found-image" 
      />
      <h1 className="not-found-heading">Oops! Page Not Found</h1>
      <p className="not-found-paragraph">
        We couldn't find the page you were looking for. It might have been removed, had its name changed, or is temporarily unavailable. 
      </p>
      <p className="not-found-paragraph">Please check the URL or return to the homepage.</p>
      <a href="/" className="not-found-home-link">Go to Homepage</a>
    </div>
  );
};

export default NotFound;
