// Modal.js
import React from 'react';
import './Styles/Success.css'; // Create this CSS file for styling the modal
import { FaCheckCircle } from 'react-icons/fa';
const Success = ({ isOpen, onClose, message }) => {
    if (!isOpen) return null;

    return (
        <div className="ss-modal-overlay" onClick={onClose}>
            <div className="ss-modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>
                <FaCheckCircle style={{ color: 'green', marginRight: '10px' }} />
                Payment Successful!
            </h3>
                <p>{message}</p>
                <button className="ss-close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Success;
