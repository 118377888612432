import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import Register from './Components/Register';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Components/Home';
import Profile from './Components/Profile';

import Step1 from './Components/RegistrationSteps/Step1';
import ForgotPassword from './Components/ForgotPassword';
import SettingsPage from './Components/SettingsPage';
import MatchingPreferences from './Components/MatchingPreferences';
import UserDetail from './Components/UserDetail';
import RecentlyViewed from './Components/RecentlyViewed';

import SubscriptionPage from './Components/SubscriptionPage';
import Subcription from './Components/Subcription';
import NotFound from './Components/NotFound';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsConditions from './Components/TermsConditions';
import ContactUs from './Components/ContactUs';
import FAQ from './Components/FAQ';
import DeleteAccount from './Components/DeleteAccount';
import Main from './Components/Main';
import ContactMain from './Components/ContactMain';
import AboutUs from './Components/About';
import RateUs from './Components/Rateus';
import Logsucc from './Components/Logsucc';




const App = () => {
  return (
    <Router>
      <div>
        <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/user/:userId" element={<UserDetail/>} />
          <Route path="/succ" element={<Logsucc/>} />
          <Route path="/register" element={<Register />} />
         <Route path="/home" element={<Home/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/termsconditions" element={<TermsConditions/>} />
          <Route path="/payment1" element={<SubscriptionPage/>} />
                 <Route path="/payment" element={<Subcription/>} /> 
         <Route path="/recently-viewed" element={<RecentlyViewed/>} /> 
          <Route path="/Step1" element={<Step1/>} />
          <Route path="/forgotpassword" element={<ForgotPassword/>} /> 
          <Route path="/settings" element={<SettingsPage/>} />
         <Route path="/profile" element={<Profile/>} /> 
          <Route path="*" element={<NotFound/>} /> {/* Handle 404 errors */}
            <Route path="/matching-preference" element={<MatchingPreferences/>} /> 
          <Route path="/contact" element={<ContactUs/>} />
          <Route path="/faq" element={<FAQ/>} />
        <Route path="/delete-account" element={<DeleteAccount/>} />
          <Route path="/" element={<Main/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/rate-us" element={<RateUs/>} />
          <Route path="/contact-us" element={<ContactMain/>} />
          
          
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;
