import React from 'react';
import './Styles/PhotoModal.css';

const PhotoModal = ({ photoUrl, onClose }) => {
  return (
    <div className="photo-modal-overlay" onClick={onClose}>
      <div className="photo-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="photo-modal-close-button" onClick={onClose}>&times;</span>
        <img src={photoUrl} alt="Profile" className="photo-modal-image" />
      </div>
    </div>
  );
};

export default PhotoModal;
