import { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import Step1 from './RegistrationSteps/Step1';
import Step2 from './RegistrationSteps/Step2';
import Step3 from './RegistrationSteps/Step3';
import Step4 from './RegistrationSteps/Step4';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { toast } from 'react-toastify';
import { doc, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Personal Information
    email: '',  // Email is now part of formData
    country: '',
    gender: '',
    maritalStatus: '',
    dateOfBirth: '',
    timeOfBirth: '',
    rashi: '',
    nakshatram: '',
    gotram: '',
    birthPlace: '',
    height: '',
    mobileNumber: '',
    password: '',
    registrationDate: '', // New field for registration date
    
    // Family Information
    fatherName: '',
    fatherProfession: '',
    fatherDesignation: '',
    motherName: '',
    motherSurname: '',
    motherProfession: '',
    altMobileNumber: '',
    numberOfBrothers: '',
    numberOfSisters: '',
    status:'',
    
    // Address Information
    state: '',
    nativePlaceDistrict: '',
    nativeStateOther: '',
    place: '',
    presentResidence: '',
    
    // Education/Professional Information
    education: '',
    collegeName: '',
    professionSector: '',
    profession: '',
    professionOther: '',
    companyName:'',
    workingPlace:'',
    
    // Partner Preference
    partnerPreferenceType: '',
    nriCountry: '',
    partnerstate: '',
    partnerprofessionSector: '',
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    // Check if the current step is 1 and if the email is not provided
    if (currentStep === 1 && !formData.email) {
      toast.error('Email is required to proceed.');
      return;
    }
    if (currentStep === 1) {
      if (!formData.password) {
        alert('Please create a password.');
        return;
      }
      
      if (formData.password.length < 6) {
        toast.error('Password must be at least 6 characters long.');
        return;
      }
    }

    if (currentStep === 1 && !formData.fullName) {
      alert('Enter Full name ');
      return;
    }

    if (currentStep === 1 && !formData.residenceType) {
      alert('Please select residenceType');
      return;
    }

    if (currentStep === 1 && !formData.caste) {
      alert('Please select caste ');
      return;
    }

    if (currentStep === 1 && !formData.religion) {
      alert('Please select religion ');
      return;
    }

    if (currentStep === 1 && !formData.mobileNumber) {
      alert('Please fill mobile number ');
      return;
    }
    if (currentStep === 1 && !formData.height) {
      alert('Please select height ');
      return;
    }
    if (currentStep === 1 && !formData.maritalStatus) {
      alert('Select marital status ');
      return;
    }

    if (currentStep === 1 && !formData.dateOfBirth) {
      alert('Select Date of birth ');
      return;
    }
    if (currentStep === 1 && !formData.gender) {
      alert('Select your gender ');
      return;
    }
    

    if (currentStep === 2 && !formData.fatherName) {
      alert('Please enter father name ');
      return;
    }
    if (currentStep === 2 && !formData.motherName) {
      alert('Please enter mother name ');
      return;
    }
    if (currentStep === 2 && !formData.state) {
      alert('Please select native state ');
      return;
    }
    if (currentStep === 2 && !formData.presentResidence) {
      alert('Please enter present residence ');
      return;
    }
    if (currentStep === 3 && !formData.education) {
      alert('Please enter education ');
      return;
    }
    if (currentStep === 3 && !formData.profession) {
      alert('Select your profession ');
      return;
    }
    if (currentStep === 3 && !formData.professionSector) {
      alert('Select your profession sector ');
      return;
    }

    if (currentStep === 4 && !formData.partnerprofessionSector) {
      alert('Select your partner profession sector ');
      return;
    }


    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
        // Capture the current date and time
        const now = new Date();
        const registrationDate = now.toISOString(); // Store in ISO format
        formData.registrationDate = registrationDate; // Add to formData

        // Create a user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        
        // Save the user data along with the registration date in Firestore
        await setDoc(doc(db, 'users', userCredential.user.uid), {
            ...formData,
            registrationDate: registrationDate, // Ensure the registration date is stored
            createdAt: now // Optional: Store the creation time as well
        });
        
        toast.success('Registration successful!');
        setSuccess(true);
        
        setTimeout(() => {
            navigate('/succ');
        }, 2000);
    } catch (error) {
        setLoading(false);
        if (error.code === 'auth/email-already-in-use') {
            toast.error('Email already in use.');
        } else {
            toast.error('Registration failed.');
        }
    }
};


  // Loading spinner
  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  // Success message
  if (success) {
    return (
      <div className="success-container">
        <h2>Registration Successful!</h2>
        <p>You have registered successfully. You will be redirected to the login page shortly.</p>
      </div>
    );
  }

  return (
    <div className="registration-form">
     
      <div class="header-container">
        
    <h1 class="header-title">🌷 Karanki Matrimony 🌷</h1>
</div>
<div className="yo">
  <p>
    Already have an account? 
    <Link to="/login" className="lo">
      <FontAwesomeIcon icon={faUser} style={{ marginRight: '5px' }} />
      Login
    </Link>
  </p>
</div>


      {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} />}
      {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} />}
      {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} />}
      {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} />}

      <div className="form-navigation">
        {currentStep > 1 && (
          <button className="form-nav-btn prev-btn" onClick={handlePrevious}>
          <FontAwesomeIcon icon={faArrowLeft} /> Previous
        </button>
        )}
        {currentStep < 4 && (
          <button className="form-nav-btn next-btn" onClick={handleNext}>
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
        )}
        {currentStep === 4 && (
          <button className="form-nav-btn submit-btn" onClick={handleSubmit}>
          Submit <FontAwesomeIcon icon={faPaperPlane} />
        </button>
        )}
      </div>
    </div>
  );
};

export default Register;
