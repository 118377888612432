import React from 'react';
import { Helmet } from 'react-helmet';
import './Styles/About.css'; // Import the updated CSS file
import MainNav from './MainNav';
import Footer from './Footer';
const About = () => {
    return (
        <div>
            <MainNav />
            <div className="aw">
                <Helmet>
                    <title>About Karanki Matrimony - Best Gowda Matrimony Service</title>
                    <meta
                        name="description"
                        content="Karanki Matrimony is a trusted platform that has successfully settled 400+ matches. Our user-friendly interface and extensive database make it easy to find the perfect life partner."
                    />
                    <meta name="keywords" content="Karanki Matrimony, best matrimony, Gowda marriage, Gowda matchmaking, Gowda community, marriage service" />
                </Helmet>

                <header className="ah">
                    <h1 className="ahg">About Karanki Matrimony</h1>
                    <h2 className="ahs">Best Matrimony Service in Andhra Pradesh & Telangana</h2>
                    <p className="at">Join us and be part of 400+ success stories, where happy marriages are made!</p>
                </header>

                <section className="ai">
                    <div className="as am">
                        <h2 className="sh">Our Mission</h2>
                        <p className="st">
                            Our mission is to provide a trustworthy and simple matrimonial service for individuals in the Gowda community and beyond. We focus on making the search for life partners stress-free while preserving traditions and values.
                        </p>
                    </div>

                    <div className="as aw">
                        <h2 className="sh">Who We Are</h2>
                        <p className="st">
                            Karanki Matrimony is driven by experienced professionals dedicated to connecting families and fostering relationships. We blend technology with cultural respect to offer a seamless matchmaking experience.
                        </p>
                    </div>

                    <div className="as af">
                        <h2 className="sh">Why Choose Us</h2>
                        <ul className="fl">
                            <li className="fi">Proven success with over 400+ matches.</li>
                            <li className="fi">Comprehensive profiles for better matchmaking.</li>
                            <li className="fi">Data privacy and security you can rely on.</li>
                            <li className="fi">User-friendly platform with easy navigation.</li>
                            <li className="fi">Supportive team available to assist you.</li>
                        </ul>
                    </div>
                </section>
                
            </div>
            <section className="contact-section">
                    <h2 className="section-title">Contact Us</h2>
                    <p className="section-text">For any inquiries, please reach out to us via our <a href="/contact-us" className="contact-link">Contact Page</a>.</p>
                </section>
                <Footer/>
        </div>
    );
};

export default About;
